import React from 'react';
import Navbar from './Navbar';


const Header = () => {
    return(
        <section className='header'>
            <Navbar />
        </section>
    )
}

export default Header;